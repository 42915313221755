import React from 'react';
import { makeStyles, Card, CardContent, Theme, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import MuiButton from './MuiButton';

const NewsItem: React.FunctionComponent<NewsItemProps> = (props) => {
  const { date, title, intro, imageUrl, url } = props;

  const placeholder = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "car-placeholder.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const useStyles = makeStyles((theme: Theme) => ({
    card: {
      borderRadius: 0,
      marginBottom: 60,
    },
    cardContent: {
      padding: '40px 45px',
    },
    date: {
      fontSize: 14,
      letterSpacing: 1,
      color: '#AAAAAA',
      marginTop: 0,
      marginBottom: 5,
    },
    image: {
      maxHeight: '100%',
      minHeight: 276,
      height: 276,
    },
    title: {
      marginTop: 0,
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 1.3,
      minHeight: 62,
    },
    intro: {
      fontSize: 15,
      marginBottom: 45,
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={0}>
      <div>
        <Link aria-label="View article" to={`/newsroom/${url}/`}>
          <GatsbyImage
            className={classes.image}
            alt={title}
            image={imageUrl || placeholder.file.childImageSharp.gatsbyImageData}
          />
        </Link>
      </div>

      <CardContent className={classes.cardContent}>
        <p className={classes.date}>{date}</p>

        <Link aria-label={title} to={`/newsroom/${url}/`}>
          <h2 className={classes.title}>{title}</h2>
        </Link>

        <Typography
          key="newsItem-intro"
          className={classes.intro}
          dangerouslySetInnerHTML={{ __html: intro }}
        />

        <Link aria-label="Read more" to={`/newsroom/${url}/`}>
          <MuiButton aria-label="Read more" name="read-more" variant="outlined">
            Read more
          </MuiButton>
        </Link>
      </CardContent>
    </Card>
  );
};

interface NewsItemProps {
  date: string;
  imageAlt?: string;
  imageUrl: any;
  intro: string;
  title: string;
  url: string;
}

export default NewsItem;
