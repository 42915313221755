import React, { useState, useRef, useEffect } from 'react';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import Slider from 'react-slick';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

// Functions
import { getMonth } from '../utils/utils';

// Config
import { styleConfig } from '../../config/config';

// Components
import HeroNavigation from './SliderNavigation';
import MuiButton from './MuiButton';
import RouteLink from './RouteLink';
import Title from './Common/Title';

const Hero: React.FunctionComponent<HeroProps> = ({
  subtitle,
  headline,
  buttons,
  content,
  slides,
  isSmall,
  isMedium,
  isMonth,
  isSearchButton,
  isCtaAppended,
  isLoadedScripts,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isHighlight, setIsHighlight] = useState(false);
  const slider = useRef(null);

  const settings = {
    dots: false,
    lazyLoad: 'ondemand',
    draggable: false,
    infinite: false,
    pagination: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 350,
  };

  const headerHeight = (isMedium && 650) || (isSmall && 450) || 852;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'relative',
      height: headerHeight,
      minHeight: 345,
      overflow: 'hidden',
      width: '100%',
      background: styleConfig.color.bg.darkgrey,

      [theme.breakpoints.down('lg')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('sm')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('xs')]: {
        height: isSmall || isMedium ? `calc(${headerHeight}px - 105px)` : '750px',
      },
    },
    image: {
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
    slider: {
      position: 'relative',
      width: '100%',
      lineHeight: 0,
      zIndex: 'auto',
    },
    variant: {
      background: styleConfig.color.bg.white,
      bottom: 0,
      position: 'absolute',
      right: 0,
      zIndex: 950,
      lineHeight: 1.4,
    },
    variantWrapper: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    variantInner: {
      background: styleConfig.color.bg.darkgrey,
      minWidth: 420,
      padding: '30px 45px',

      [theme.breakpoints.down('md')]: {
        minWidth: 420,
        padding: 15,
      },

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    variantCar: {
      backgroundColor: styleConfig.color.bg.white,
      width: 250,
      height: '100%',
      minHeight: 164,
      position: 'relative',
    },
    slide: {
      position: 'relative',
      zIndex: 750,
      height: (isSmall && 'calc(450px + 2px)') || (isMedium && headerHeight) || 'calc(852px + 2px)',

      [theme.breakpoints.down('lg')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('sm')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('xs')]: {
        height: isSmall || isMedium ? `calc(${headerHeight}px - 105px)` : '750px',
      },
    },
    slideInner: {
      zIndex: 800,
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    slideText: {
      color: styleConfig.color.text.white,
      marginBottom: 40,
      maxWidth: '45%',

      [theme.breakpoints.down('md')]: {
        maxWidth: '60%',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    slideContentFixed: {
      left: 25,
      height: '100%',
      display: 'flex',
      zIndex: 80,
      position: 'absolute',
      maxWidth: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '50%',

      [theme.breakpoints.down('md')]: {
        left: '5%',
        height: '100%',
        padding: 15,
        width: '100%',
      },

      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
    },
    navigation: {
      bottom: 0,
      right: 0,
      position: 'absolute',
      zIndex: 99,
      minWidth: 52,
    },
    highlight: {
      bottom: 162,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        bottom: 0,
      },
    },
    slideContent: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      top: 0,
      height: isSmall ? '100%' : 'calc(100% - 157px)',
      zIndex: 50,

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        width: '75%',
      },

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        justifyContent: isSmall ? 'center' : 'start',
        padding: 50,
        width: '100%',
        alignItems: 'center',

        '& h1': {
          [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
          },
        },
      },

      [theme.breakpoints.down('xs')]: {
        padding: 25,
        justifyContent: isSmall ? 'center' : 'start',
      },
    },
    variantInnerHighlight: {
      color: styleConfig.color.text.gold,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 2,
      textTransform: 'uppercase',
      fontFamily: 'Bai Jamjuree',
      margin: 0,
    },
    variantModel: {
      fontSize: 20,
      fontFamily: 'Bai Jamjuree',
      margin: 0,
      color: styleConfig.color.text.white,
    },
    variantTitle: {
      color: '#999999',
      fontSize: 16,
    },
    btnGroupRoot: {
      display: 'flex',

      '& > a': {
        marginRight: 30,
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%',

        '& > a': {
          marginRight: 0,
        },

        '& button': {
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 15,
          },
        },
      },
    },
    reviewsWidget: {
      width: 385,
      height: 92,
      overflow: 'hidden',
      maxWidth: 488,
      padding: 10,
      backgroundColor: 'rgb(255 255 255 / 40%)',
      marginTop: 50,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 15,

      [theme.breakpoints.down('xs')]: {
        width: 384,
        maxWidth: '92%',
        overflow: 'hidden',
      },
    },
  }));

  const classes = useStyles();
  const slidesLength = (slides && slides.length) || 0;

  const handleNext = () => {
    // @ts-ignore: Unknown Slick Slider ref type
    slider.current.slickGoTo(slideIndex < slidesLength - 1 ? slideIndex + 1 : slideIndex);
    setSlideIndex(slideIndex < slidesLength - 1 ? slideIndex + 1 : slideIndex);
  };

  const handlePrev = () => {
    // @ts-ignore: Unknown Slick Slider ref type
    slider.current.slickGoTo(slideIndex > 0 ? slideIndex - 1 : slideIndex);
    setSlideIndex(slideIndex > 0 ? slideIndex - 1 : slideIndex);
  };

  useEffect(() => {
    const isHighlight = !isNull(slides) ? slides && slides.map((slide) => slide.highlight) : [];

    if (!isEmpty(isHighlight)) {
      setIsHighlight(true);
    }
  }, [slides]);

  return (
    <div className={classes.root}>
      <div className={classes.slideContentFixed}>
        <div style={{ pointerEvents: 'all' }}>
          <Title
            component="h1"
            isSubtitle={typeof subtitle === 'string'}
            subtitleText={subtitle}
            subtitleType="subtitle1"
            titleCaps
            titleColor="white"
            titleHeavy
            titleSize="h1"
            titleText={(headline && headline) || ''}
            marginBottom={50}
          >
            {isMonth && getMonth()}
          </Title>
          {content && (
            <Box
              component="div"
              key="hero-content"
              className={classes.slideText}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>

        {!isNull(buttons) && buttons && (
          <>
            <div className={classes.btnGroupRoot}>
              {isSearchButton && !isCtaAppended && (
                <MuiButton
                  aria-label="Find your perfect car"
                  height={61}
                  labelColor={styleConfig.color.text.white}
                  variant="outlined"
                  href="/search?route=home"
                >
                  <span>Find your perfect car</span>
                </MuiButton>
              )}

              {buttons.map(
                (block, index) =>
                  block.button &&
                  block.button.url && (
                    <RouteLink key={`hero-buttons-${index}`} href={block.button.url}>
                      <MuiButton
                        aria-label={block.button.title}
                        height={61}
                        labelColor={styleConfig.color.text.white}
                        name={`hero-button-group-${index}`}
                        variant={block.type === 'ghost' ? 'outlined' : 'contained'}
                        href={
                          (typeof window !== 'undefined' &&
                            window.location.href.indexOf('#') > -1 &&
                            block.button.url) ||
                          undefined
                        }
                      >
                        <div dangerouslySetInnerHTML={{ __html: block.button.title }} />
                      </MuiButton>
                    </RouteLink>
                  )
              )}

              {isSearchButton && isCtaAppended && (
                <MuiButton
                  aria-label="Find your perfect car"
                  height={61}
                  labelColor={styleConfig.color.text.white}
                  variant="outlined"
                  href="/search?route=home"
                >
                  <span>Find your perfect car</span>
                </MuiButton>
              )}
            </div>

            {isSearchButton && isLoadedScripts && (
              <div className={classes.reviewsWidget}>
                <div id="badge-ribbon" />
              </div>
            )}
          </>
        )}
      </div>

      {!isNull(slides) && !isEmpty(slides) && slides && (
        <Slider {...settings} ref={slider} className={classes.slider}>
          {slides.map((slide, index) => {
            const highlight = get(slide, 'highlight[0]', []);
            const modelTitle = get(highlight, 'post_meta_fields.brand_title', '');
            const variantTitle = get(highlight, 'post_meta_fields.title', '');
            const variantSlug = get(highlight, 'post_meta_fields.variant_url', '');
            const capId = get(highlight, 'post_meta_fields.capid', '');

            const baseImageUrl = `https://cdn.imagin.studio/getImage?customer=${process.env.GATSBY_SITE_IMAGIN_ID}&capVehicleType=car&capId=${capId}&zoomType=fullscreen&safeMode=true`;
            const highlightImage = `${baseImageUrl}&angle=01`;

            const slideImage = slide.image?.localFile;
            const variantUrl = variantSlug;
            const image = getImage(slideImage);
            const slideBg = convertToBgImage(image);

            return (
              <div key={`hero-variant-${index}`} hidden={slideIndex !== index}>
                {slide && slideImage && (
                  <BackgroundImage fadeIn={false} Tag="div" {...slideBg} className={classes.image}>
                    <Container maxWidth="xl">
                      <Grid
                        container
                        justifyContent="center"
                        direction="column"
                        className={classes.slide}
                      >
                        <div className={classes.slideInner}></div>
                      </Grid>
                    </Container>
                  </BackgroundImage>
                )}

                {!isEmpty(slide && slide.highlight) && variantUrl && (
                  <div style={{ opacity: index !== slideIndex ? 0 : 1, position: 'relative' }}>
                    <RouteLink
                      href={variantUrl}
                      className={clsx([classes.variant, classes.variantWrapper])}
                    >
                      {highlightImage && (
                        <img
                          className={classes.variantCar}
                          src={highlightImage}
                          alt={variantTitle}
                        />
                      )}
                      <div className={classes.variantInner}>
                        <Typography className={classes.variantInnerHighlight}>Highlight</Typography>
                        <Typography className={classes.variantModel}>{modelTitle}</Typography>
                        <Typography className={classes.variantTitle}>{variantTitle}</Typography>
                        <ArrowRightAltIcon color="primary" />
                      </div>
                    </RouteLink>
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
      )}

      {!isSmall && slides && slidesLength >= 2 && (
        <div className={clsx(classes.navigation, isHighlight ? classes.highlight : '')}>
          <HeroNavigation
            handleNext={handleNext}
            handlePrev={handlePrev}
            showCount
            slideIndex={slideIndex}
            slidesTotal={slidesLength}
            style="dark"
          />
        </div>
      )}
    </div>
  );
};

Hero.defaultProps = {
  isCtaAppended: false,
};

interface HeroProps {
  isLoadedScripts?: boolean | undefined;
  isCtaAppended?: boolean;
  isMonth?: boolean;
  isSearchButton?: boolean;
  subtitle: string;
  headline: string;
  content: string;
  isSmall?: boolean;
  isMedium?: boolean;
  slides?: {
    highlight: {
      post_meta_fields: {
        capid: string;
        brands: {
          item: string;
          name: string;
        };
      };
    };
    image: any;
  }[];
  buttons?: {
    button: {
      title: string;
      url: string;
    };
    type: string;
  }[];
}

export default Hero;
