import React from 'react';
import { Container, Grid } from '@material-ui/core';

const PostsList = (props) => {
  const { children } = props;

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        {children}
      </Grid>
    </Container>
  );
};

export default PostsList;
