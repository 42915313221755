import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { styleConfig } from '../../config/config';
import Hero from '../components/Hero';
import LayoutArchive from '../components/LayoutArchive';
import NewsItem from '../components/NewsItem';
import Pagination from '../components/Pagination';
import PostsList from '../components/PostsList';
import Section from '../components/Common/Section';
import SEO from '../components/SEO';
import { decodeObjectSpecialCharacters } from '../utils/utils';
import PageBreadcrumb from '../components/PageBreadcrumb';

const Homepage = (props) => {
  const { data, pageContext } = props;
  const yoastMeta = decodeObjectSpecialCharacters(data.wordpressPage.yoast_meta);

  const crumbs = [
    {
      pathname: '/',
      crumbLabel: 'Home',
    },
    {
      pathname: `/newsroom`,
      crumbLabel: 'Newsroom',
    },
  ];

  return (
    <main>
      <SEO
        description={yoastMeta.yoast_wpseo_metadesc}
        ogDesc={yoastMeta.yoast_wpseo_facebook_description}
        ogImg={yoastMeta.yoast_wpseo_facebook_image}
        ogTitle={yoastMeta.yoast_wpseo_facebook_title}
        title={yoastMeta.yoast_wpseo_title}
        twitterDesc={yoastMeta.yoast_wpseo_twitter_description}
        twitterImg={yoastMeta.yoast_wpseo_twitter_image}
        twitterTitle={yoastMeta.yoast_wpseo_twitter_title}
      />

      <LayoutArchive>
        <Hero
          buttons={data.wordpressPage.acf.buttons}
          content={data.wordpressPage.acf.content}
          headline={data.wordpressPage.page_meta_fields.title}
          slides={data.wordpressPage.acf.slides}
          subtitle={data.wordpressPage.acf.pre_title}
          isSearchButton={props.pageContext.isHome}
          isSmall
        />

        <PageBreadcrumb crumbs={crumbs} location={props.location} />

        <Section bg={styleConfig.color.bg.offwhite}>
          <PostsList>
            {!isEmpty(data.allWordpressPost.edges) && data.allWordpressPost.edges ? (
              data.allWordpressPost.edges.map((post) => (
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={uniqueId()}>
                  <NewsItem
                    date={post.node.date}
                    imageAlt={post.node.title}
                    imageUrl={get(
                      post,
                      'node.acf.featured_image.localFile.childImageSharp.gatsbyImageData'
                    )}
                    intro={post.node.excerpt}
                    title={post.node.title}
                    url={post.node.slug}
                  />
                </Grid>
              ))
            ) : (
              <Typography align="center">No published articles</Typography>
            )}
          </PostsList>

          <Pagination
            slug="newsroom"
            currentPage={pageContext.activePage}
            totalPages={pageContext.totalPages}
          />
        </Section>
      </LayoutArchive>
    </main>
  );
};

export const query = graphql`
  query newsPageQuery($id: Int!, $skip: Int!, $limit: Int!) {
    allWordpressPost(
      limit: $limit
      skip: $skip
      filter: { slug: { nin: "demo" } }
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          title
          excerpt
          title
          date(formatString: "MMMM YY")
          slug
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      wordpress_id
      slug
      status
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        pre_title
        buttons {
          button {
            title
            url
            target
          }
        }
        content
        slides {
          highlight {
            post_meta_fields {
              capid
              brand_title
              title
              variant_url
              __typename
            }
          }
          image {
            alt_text
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      page_meta_fields {
        title
      }
      title
    }
  }
`;

export default Homepage;
