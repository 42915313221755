/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Container, Grid, Typography, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import * as Yup from 'yup';

// Config
import { styleConfig } from '../../config/config';

// Components
import MuiButton from './MuiButton';

const EmailSignup: React.FunctionComponent = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);

  const data = useStaticQuery(graphql`
    {
      wordpressAcfOptions {
        options {
          twitter
        }
      }

      iconTwitter: file(relativePath: { eq: "icons/x.svg" }) {
        publicURL
      }
    }
  `);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      padding: '75px 0',
      minHeight: 220,
      overflowX: 'hidden',
    },
    title: {
      fontSize: 24,
      textTransform: 'uppercase',
      fontWeight: 600,
      color: theme.palette.primary.dark,

      [theme.breakpoints.down('lg')]: {
        marginBottom: 20,
      },
    },
    input: {
      marginRight: 25,
      minWidth: 250,

      [theme.breakpoints.down('md')]: {
        marginBottom: 20,
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      MuiInputBase: {
        borderRadius: 0,
      },
    },
    followRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: 56,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
        marginTop: 50,
        justifyContent: 'flex-start',
      },
    },
    followList: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      '& > li': {
        marginRight: 15,
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    followTitle: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      letterSpacing: 2,
      margin: 0,
      marginRight: 10,
    },
    icon: {
      cursor: 'pointer',
      fill: theme.palette.primary.dark,
      width: 24,
      transition: '0.3s ease-in-out fill',
      '&:hover': {
        fill: styleConfig.color.text.gold,
      },
    },
  }));

  const classes = useStyles(props);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  return (
    <div className={classes.root} key="email-signup">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: '',
        }}
        onSubmit={(values) => {
          if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event', 'click', {
              event_category: 'subscribe',
            });
          }

          setIsSubmitting(true);

          return fetch(`${process.env.GATSBY_SITE_URL}/wp-json/wp_mailchimp/v1/subscribe`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              key: 'secretpasskey',
              email: values.email,
            }),
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.status === 200) {
                  setIsSubmit(true);
                  setIsSubmitting(false);
                  return;
                }
              } else {
                setIsError(true);
                setIsSubmitting(false);
                return;
              }
            })
            .catch(() => {
              setIsError(true);
              setIsSubmitting(false);
            });
        }}
      >
        {({ handleChange, handleBlur, values, errors }) => (
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xl={4} lg={12} sm={12} xs={12}>
                <Typography className={classes.title}>
                  Receive the latest offers &amp; news
                </Typography>
              </Grid>

              <Grid item xl={4} lg={8} md={6} sm={12} xs={12}>
                {isError && (
                  <Typography variant="h4" color="error">
                    There was an error. Please try again at a later time
                  </Typography>
                )}

                {!isError && isSubmit && (
                  <Typography id="form-newsletter-confirm">Thanks for joining the list</Typography>
                )}

                {!isSubmitting && !isError && !isSubmit && (
                  <Form name="signup">
                    <TextField
                      inputProps={{
                        'aria-label': 'Email Address',
                      }}
                      className={classes.input}
                      disabled={isSubmitting}
                      error={typeof errors.email === 'string'}
                      name="email"
                      onBlur={handleBlur}
                      label="Email"
                      onChange={handleChange}
                      placeholder="Enter email"
                      type="string"
                      value={values.email}
                      variant="outlined"
                    />

                    <MuiButton
                      aria-label="Subscribe to Newsletter"
                      disabled={isSubmitting}
                      fontWeight={400}
                      height={54}
                      name="newsletter-subscribe"
                      type="submit"
                      variant="outlined"
                    >
                      Subscribe
                    </MuiButton>
                  </Form>
                )}
              </Grid>

              <Grid item xl={4} lg={4} md={6} xs={12}>
                <div className={classes.followRoot}>
                  <Typography className={classes.followTitle}>Follow us on:</Typography>

                  <ul className={classes.followList}>
                    {data.wordpressAcfOptions.options.twitter && (
                      <li>
                        <a
                          href={data.wordpressAcfOptions.options.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Follow us on Twitter"
                        >
                          <img
                            width={24}
                            height={24}
                            alt="Follow us on Twitter"
                            src={data.iconTwitter.publicURL}
                            className={classes.icon}
                          />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Container>
        )}
      </Formik>
    </div>
  );
};

export default EmailSignup;
