import React from 'react';
import ReactPaginate from 'react-paginate';
import { makeStyles, Theme, Box } from '@material-ui/core';
import { navigate } from 'gatsby';

import { styleConfig } from '../../config/config';

const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  const { totalPages, currentPage, slug } = props;

  const handlePageSelect = (page) => {
    // If it is the first page it should go to the base URL for the post type
    if (page === 0) {
      return navigate(`/${slug}`);
    }

    // Otherwise navigate to the archive page
    return navigate(`/${slug}/p/${page + 1}`);
  };

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      alignItems: 'center',
      backgroundColor: styleConfig.color.bg.offwhite,
      display: 'flex',
      justifyContent: 'center',
      padding: '50px 0',

      '& > ul': {
        display: 'flex',
        listStyle: 'none',
        padding: 0,
        margin: 0,

        '& > li': {
          '&.active': {
            '& > a': {
              backgroundColor: styleConfig.color.bg.gold,
              color: styleConfig.color.text.white,
            },
          },
          '& > a': {
            height: 50,
            padding: 15,
            minWidth: 50,
            marginRight: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#E2E2E2',
            fontFamily: styleConfig.fonts.header,
            color: styleConfig.color.text.darkgrey,
            fontWeight: 600,
            fontSize: 18,
            transition: '0.3s ease-in-out all',
            cursor: 'pointer',

            '&:hover': {
              backgroundColor: styleConfig.color.bg.gold,
              color: styleConfig.color.text.white,
            },
          },

          '&.previous': {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },

            '&.disabled': {
              '& > a': {
                color: '#E2E2E2',
                cursor: 'no-drop',

                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },

            '& > a': {
              padding: '0 30px',
              textTransform: 'uppercase',
              backgroundColor: 'transparent',
              border: '1px #E2E2E2 solid',

              '&:hover': {
                backgroundColor: styleConfig.color.bg.gold,
                color: styleConfig.color.text.white,
              },
            },
          },

          '&.next': {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },

            '&.disabled': {
              '& > a': {
                color: '#E2E2E2',
                cursor: 'no-drop',

                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },

            '& > a': {
              padding: '0 30px',
              textTransform: 'uppercase',
              backgroundColor: 'transparent',
              border: '1px #E2E2E2 solid',

              '&:hover': {
                backgroundColor: styleConfig.color.bg.gold,
                color: styleConfig.color.text.white,
              },
            },
          },
        },
      },
    },
  }));

  const classes = useStyles();

  if (totalPages > 1) {
    return (
      <Box className={classes.root}>
        <ReactPaginate
          initialPage={currentPage}
          activeClassName="active"
          breakClassName="break"
          breakLabel="..."
          containerClassName="pagination"
          marginPagesDisplayed={2}
          nextLabel="Next"
          onPageChange={({ selected }) => handlePageSelect(selected)}
          pageCount={totalPages}
          pageRangeDisplayed={5}
          previousLabel="Previous"
          disableInitialCallback
        />
      </Box>
    );
  }

  return null;
};

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  slug: string;
}

export default Pagination;
